@import "../../../../../stylesheets/sass/partials/responsive_mixins";

.modal-drawer {
  :global {
    .modal-close {
      position: fixed;

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -20px;
        left: -20px;
        z-index: -1;
      }

      @include mobile-breakpoint {
        position: absolute;
        top: 15px;
      }
    }
  }

  &.modal-close-circle-hide {
    touch-action: none;
    :global {
      .modal-close {
        display: none;
      }
    }
  }
}

.modal-drawer-wrapper {
  padding: 0;
}

.modal-drawer-content {
  transition: max-height 0.3s ease;
  -webkit-transition: max-height 0.3s ease;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drag-handle {
  background-color: white;
  display: flex;
  height: 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  overflow: hidden;

  &-hide {
    height: 0;
    transition: height 0.3s ease;
  }

  &-inner {
    width: 34px;
    height: 4px;
    background-color: lightgray;
    border-radius: 5px;
  }
}

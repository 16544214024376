@import "partials/_variables";
@import "partials/_mixins";

.highlighted-component {
  &-container {
    position: unset;
    pointer-events: none;
  }

  &-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  &-target {
    z-index: 1001;
    position: relative;
  }

  &-tool-tip {
    &-container {
      z-index: 1001;
      position: fixed;
      display: flex;
      flex-direction: column;

      width: 100%;
    }
  
    &-body {
      padding: 20px;
      background-color: $color-white;
      line-height: 24px;
      text-align: left;
      gap: 16px;
      display: flex;
      flex-direction: column;
      color: black;

      width: 355px;

      &-mobile {
        width: calc(100% - 80px);
        margin-left: 20px;
      }
    }

    &-arrow {
      &-up {
        position: absolute;
        top: -9px;
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        
        border-bottom: 10px solid white;
      }
    }
  }
}

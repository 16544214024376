@use "sass:math";
@import "../../../stylesheets/design-system/typography/typography-mixins";

// Fonts
// DEPRECATED: Use standardized mixins from design-system/typography/_typography-mixins.scss
$font-size-heading: 18px; // Remove
$font-size-heading-large: 36px; // Remove
$font-size-heading-medium: 20px;
$font-size-title: 14px;
// Input font has to be more than 16px on iOS
// otherwise apple has some native zooming behavior
$font-size-mobile-input: 16px;
$font-size-subtitle: 12px;
$font-size-default: 14px;
$font-size-input: 16px;
$font-size-editorial: 18px;
$font-size-small: 14px;
$font-size-label: 14px;
$font-size-promotional: 22px;

$font-size-body-one: 16px;
$font-size-body-two: 14px;
$font-size-body-three: 12px;

$font-size-heading-one: 60px;
$font-size-heading-two: 36px;
$font-size-heading-three: 28px;
$font-size-heading-four: 20px;
$font-size-heading-six: 10px;

$font-size-heading-one-mobile: 28px;
$font-size-heading-two-mobile: 20px;
$font-size-heading-three-mobile: 20px;
$font-size-heading-four-mobile: 16px;
$font-size-heading-five-mobile: 14px;
$font-size-heading-six-mobile: 12px;

$font-size-cms-36: 36px;
$font-size-cms-28: 28px;
$font-size-cms-24: 24px;
$font-size-cms-20: 20px;

$font-size-about-us-header-hed: 42px;
$font-size-about-us-header-hed--mobile: 32px;
$font-size-about-us-header-dek: 32px;
$font-size-about-us-header-dek--mobile: 20px;

$font-size-about-us-checkerboard-hed: 36px;
$font-size-about-us-checkerboard-hed--mobile: 28px;
$font-size-about-us-checkerboard-dek: 18px;
$font-size-about-us-checkerboard-dek--mobile: 14px;

$font-size-greeting: 30px;

$line-height-about-us-checkerboard: 1.44;
$line-height-about-us-checkerboard--mobile: 1.5;
$line-height-about-us-header: 1.34;
$line-height-about-us-header--mobile: 1.32;

// Used for customizable atoms
$cms-font-sizes: 12, 13, 14, 16, 18, 20, 24, 26, 28, 32, 36, 38, 44;

$font-size-hero: 60px;

$font-size-home-hero: 40px;
$font-size-home-hero-mobile: 30px;

$font-size-dek-one: 20px;
$font-size-dek-two: 14px;

$line-height-hero: 60px;
$line-height-header: 1.2em;
$line-height-dek-one: 26px;
$line-height-dek-two: 22px;
$line-height-body: 22px;
$line-height-small: 18px;
$line-height-smaller: 16px;
$line-height-label: 13px;

$letter-spacing-title: 2px;
$letter-spacing-header: 1.8px;
$letter-spacing-proxima-title: 1px;
$letter-spacing-proxima-link: 0.5px;
$letter-spacing-proxima-semibold: 0.2px;
$letter-spacing-tagline: 1.5px;

$line-height-heading: 28px;

$min-device-width-mobile: 320px; //width iphone 5
// If `$max-device-width-mobile` is updated, then please update the
// `credit-card-form` component.
$min-device-width-mobile-m: 375px;
$min-device-width-mobile-l: 425px;
$max-device-width-mobile: 480px;
$min-device-width-tablet: $max-device-width-mobile + 1;
$max-device-width-tablet: 768px;
$min-device-width-laptop: 1024px;
$min-device-width-desktop: $max-device-width-tablet + 1;
$min-device-width-small-tablet: 481px;
$max-device-width-small-desktop: 1280px;
$widescreen-page-width: 1200px;
$widescreen-page-carousel-width: 1400px;
$widescreen-page-width-topnav: 1600px;
$min-device-width-widescreen: $widescreen-page-width;
$max-device-width-before-widescreen: $widescreen-page-width - 1;
$min-device-height-mobile-normal: 559px; // height of iPhone 6
$max-device-height-mobile-short: $min-device-height-mobile-normal - 1;
$max-device-height-short: 768px; // 1024x768 is a common screen resolution

$branch-banner-height: 55px;  // Height of the branch banner on mobile

// Colors

$color-background: #FFFFFF;
$color-background-one: #F7F7F7;
$color-background-two: #F4EDE5;
$color-background-three: #E5E5E5;
$color-background-four: #E8ECF8; // Clearance grid topper background
$color-background-five: #FAFAFA;
$color-background-six: #E6E0D0; // RTRUPDATE/Unlimited Onboarding Modal
$color-background-seven: #DADADA; // generic toggle unselected bar
$color-background-eight: #D7D6D6; // background of UI app like toggle
$color-background-nine: #FBF9F6; // Really pale gray
$color-background-ten: #FAF9F7;
$color-background-eleven: #F8F5F2;
$color-background-two-shadow: #F0E3D7;
$color-background-twelve: #420022;

$color-black: #000000;
$color-white: #FFFFFF;
$color-white-transparent: rgba(255, 255, 255, 0.5);

$color-seasonal-group-two: #4C6E6F; // Mainly used for PRO

// TODO(maddie): replace all uses of this var with $color-background-ten
$color-background-membership-homepage: $color-background-ten;

//we don't adhere to the seasonal colors originally set forth. They will be changing seasonally and are not set until the season arrives. The below color-seasonal-number are the current season.
$color-core-midnight: #272C32;
$color-core-white: $color-background;
$color-core-fawn: #C79176;
$color-core-lace: $color-background-two;
$color-core-lace-light: #FCF9F6;
$color-core-cashmere: #B0B7C0;
$color-core-tangerine: #E85940;
$color-core-red: #E2452B;
$color-core-peony: #FFB19F;
$color-core-blush: #F9EEEE;

$color-seasonal-coral: #FEC5AD;
$color-seasonal-tango-red: #F25848;
$color-seasonal-succulent: #5DB5BC;
$color-seasonal-lt-fawn: #CDA788;
$color-seasonal-purple: #9EABD8;
$color-seasonal-blue-sky: #2A65B4;

// Seasonal Colors are DEPRECATED and should no longer be used
$color-spring-three: #A5BCA6; // masquerade banner only

$color-summer-one: #F0C96A; // environment-banner only
$color-summer-five: #D9B48F;

$color-holiday: #C09566; //change this to the accent color to undo a holiday change.

$color-winter-two: #216BAF; // used in promo banner

$color-contrast-copy: $color-core-lace;
$color-secondary-copy: #999999;
$color-primary-copy: #000000;
$color-content-primary: #1C1C1C;
$color-content-secondary: #6F6B67;
$color-content-inverse: #FFFFFF;
$color-content-positive: #607F42;
$color-background-secondary: #F1F0EE;
$color-background-inverse: #1C1C1C;
$color-accessible-gray: #707070;
$color-content-gray-disabled: #D5D1CD;



// Errors
$color-error: #EB5840; // Red
$color-error-darker: #D7000C; // Darker Red
$color-error-shadow: #C14734;
$color-error-alert: #EB1622; // Red
$color-error-muted: #C24F43; // Muted red

$color-positive: #83CD99; // Green
$color-accent: #C09566; // Gold
$color-accent-winter: #A77A70;
$color-ecru: #DBCFBF;
$color-dark-accent: #965E31; // Accessible version of color-accent
$color-pro-light: #BBDBDC; // Lighter version of color-accent-1
$color-light-pink: #F6D9D2; // Pink
$color-khaki: #F0EEE9; // Khaki (Simplify Membership)
$color-unlimited: #F9C985; // Yellowish?
$color-update: #A7BEF1; // Light blue
$color-clearance: #DC2A2A; // Bright red, accessible
$color-dark-pink: #FB6D6D; // Dark Pink
$color-same-day: #CDD3E0; // Gray-blue
$color-disabled-return-flow: #9B9B9B; // RTRUpdate return flow, purchased/done
$color-todo-return-flow: #C7C7C7; // RTRUpdate return flow, todo/next steps
$color-disabled-background: #EFEFEF;
$color-disabled-tab: #F6F5F5;
$color-placeholder: #CCCCCC;
$color-disabled-card-footer: #C3C3C3;
$color-light-blue: #CEDCE8;
$color-beige: #F0EEE9;
$color-beige-darker: #E7DFD2;
$color-pearl: #E9DED9;
$color-pearl-light: #F5EFEC;
$color-pink: #FBF0ED;
$color-top-right-pink: #FDC8B8;
$color-aprocot: #E9D3C1;
$color-light-grey: #D5D1CD; // Changed during RTR insider update Aug 2024
$color-midnight: #091B2B; // Midnight blue
$color-dark-gray: #1C1C1C; // Almost black
$color-gray-offwhite: #F1F0EE;
$color-facebook-login: #39579A;
$color-google-login: #4285F4; // https://developers.google.com/identity/branding-guidelines
$color-twitter: #00ACED;
$color-bag-kifah: #A77A70;
$color-fit-bar-grey: #D9D9D9;
$color-fit-bar-camel: #D1B08C;

$color-burgundy: #420022;
$color-eggplant100: #E3D9DE;

$color-medium-gray: #C7C2BC;

$color-hover-input: #EEEEEE;
$color-lines: #DDDDDD;
$color-disabled-2: #D8D8D8;
$color-box-shadow: #CCCCCC;
$border-accent-color: transparentize($color-holiday, 0.6);
$border-primary-color: #1C1C1C;
$border-gold-color: #CD9365;
$border-gray-color: #E2E1E1;
$border-negative: #BA473B;
$reviews-background-color: transparentize($color-background-two, 0.5);

$color-password-weak: #EB5840; // red
$color-password-medium: #FFD051; // yellow
$color-password-strong: #83CD99; // green

// Stats Circle default color
$stats-circle-fawn: #E0C2A5;

// Icon Backgrounds
// Sometimes our SVGs have background colors that are added separately,
// via our CSS. These variables appear below and should be prefixed with
// icon-background-#{NAME}
$icon-background-glowing-star: #F7ECE7;

// Checkout Order Summary
$order-discounts-color: $color-accent; // original color: #DB2E0C;
$merch-or-gift-card-color: #416364;

// Steps modal
$color-modal-box-shadow: #BDBDBD;
$color-modal-step-number-background: $color-black;
$font-size-modal-step-header: 18px;

// Referrals
$color-referral-card: #FBF3E3;
$color-award-referral-card: #B3CCD8;

// Happiness Survey
$color-happiness-survey: #CC9369;
$happiness-survey-modal-height: 639px;
$happiness-survey-reviews-modal-min-height: 530px;

// HPU confirmation modal
$hpu-confirmation-modal-height: 564px;

// Labels
$color-label-background: #F5EAE1;

// Plans molecule
$color-plans-banner-pink: #F5C1B1;
$color-plans-highlight-coral: #F5C1B1;

// Order details page
$mercury: #E7E7E7;
$ocean-green: #3F9D6E;
$surf: #B9DBC3;
$box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.14);

// Deprecated color synonyms. Don't use them, and remove them when you find them.
$border-color: $color-lines;
$color-accent-1: #4C6E6F; // Dark green

// New Color Library (ongoing changes)
$color-progress-background: #EFF5F6;
$color-item-card-placeholder: #F0F0F0;
$color-item-card-tag-extra-spot: #51684C;
//color-background-swap-purple will replace $color-background-zumthor once ff_swap_counter is flipped
$color-background-swap-purple: rgba(140, 21, 128, 0.06);
$color-background-zumthor: #EFF6FF;
$color-background-dark-accent: #C8ACA0;
$color-gradient-homepage: #F6EDF5, #E5E7F4, #CAE5FD, #E1F0FF, #FCF0FF, #CADFFD;
$color-bag-secondary-copy: #333333;
$color-upgrade-background-pink: #FEEEEA;
$color-cta-light: #E0E0E0;
$color-info-grey: #666666;
$color-violet: #8C1580;
$color-dark-violet: #6D1141;
$color-swap-purple-background: #D3AFCF;
$color-light-magenta: #F4E8F2;
$color-medium-magenta: #EEDCEB;
$color-dark-magenta: #DDB9D9;
$color-swap-light-purple-background: rgba(140, 21, 128, 0.06);
$light-blue: #D6E5E9;
$color-navy: #0A1C4C;
$color-ai: #0A1B2C;
$color-dark-navy: #0D1B2A;
$color-highlight-blue: #EAF2F4;
$color-forest-green: #204C47;
$color-light-forest-green: #427864;
$color-light-teal-green: #E4ECDD;
$marketing-turquoise: #A0D1CA;
$color-peach: #FFCACA;
$color-border-opaque: #E3E1DE;

// Promotional 4-to-5 swap neon
$neon-green: #D9F23A;

// Color background comparison table
$pale-silver: #C6C1BB;

// According to MDN, most browsers default to a line-height of 1.2, so "normal" or "common" didn't seem to fit name-wise
$line-height-basic: 1;

// Use this 12-column grid system for new page layouts

$grid-max-column-width: 12;
$grid-column-width: 84px;
$grid-gutter: 30px;
$grid-horizontal-padding: 51px;

$fluid-grid-gutter: 2.18%;
$fluid-grid-column: 6.15%;

// Bag
$new-plan-section: $color-pearl;
$extra-spot-section: $color-dark-magenta;

// Sizes

$gutter-width: 20px;

$column-width: 80px;
$columns: 12;
$margin-unit: 10px;
$page-width: 940px;
$breakpoint-ipad-portrait: 1023px;
$breakpoint-ipad: 767px; // iPad portrait width
$breakpoint-header: 840px; // header specific breakpoint
$input-padding-vertical: 5px;
$input-padding-horizontal: 10px;

$button-height-compact: 28px;

$pagination-nav-size: 34px;
$pagination-nav-arrow-size: 8px;

$survey-width: 320px;
$new-survey-width: 370px;
$survey-footer: 76px;

$tooltip-width: 259px;

$membership-tiles: 281px;
$membership-tile-padding: 15px;

// Images

$loading: url("/images/loading.gif");
$loading-retina: url("/images/loading2x.gif");
$loading-small-retina: url("/images/loading-small2x.gif");

// Grid Items
// Grid thumb width is determined by percentages based on parent width

$grid-thumb-gutter-horizontal: 14px;
$grid-thumb-gutter-vertical: 60px;
$grid-thumb-height: 420px;
$grid-thumb-img-ratio: math.div(405, 270); // original resolution of the 270x thumbnail images
$grid-thumb-img-width: 230px;
$grid-thumb-img-height: $grid-thumb-img-width * $grid-thumb-img-ratio;

$carousel-button-width: 50px;

// Swaps
$swap-button-min-width: 250px;

// Animations
$animation-standard-duration: 350ms;

// Z-index

// z-index order should be: Google One-Tap, Auth Form, other modals, Branch banner.
// Note for $z-index-react-popup-with-auth-modal: When both the AuthForm and another modal is open,
// we will decrease the z-index of the non-AuthForm modal and hide the Branch banner
// (both dependent on class names indicating AuthForm is open; see auth-form.scss)
// to achieve this order of priority. This is because Google One-Tap and Branch banner are iframes and we
// cannot change their z-indexes. Also there is not good way to tell if a React modal is open.
$z-index-react-popup: 999999 !default;
$z-index-auth-form: $z-index-react-popup + 1 !default; // Auth modal supersedes other modals
$z-index-google-one-tap: $z-index-auth-form + 1 !default; // Google One-Tap supersedes auth modal

$z-index-overlay: 250;
$z-index-above-overlay: 275;
$z-index-onetrust-sticky-header-above-toggles: 2;
$z-index-branch-download-banner: 99999;
$z-index-integrated-bag: $z-index-branch-download-banner + 1;

// Flexbox datepicker
$datepicker-week-days-percent: math.div(1, 7) * 100%;
$datepicker-quiz-color: lighten($color-primary-copy, 40%);

// Unlimited Homepage
$unlimited-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
$unlimited-homepage-max-width: 1170px;

// These are used in items.scss
$mini-grid-thumb-height: $grid-thumb-height;
$mini-grid-thumb-img-width: 200px;
$mini-grid-thumb-img-height: $mini-grid-thumb-img-width * $grid-thumb-img-ratio;

// AtomRadioCard Padding
$atom-radio-card-padding-vertical: 30px;
$atom-radio-card-padding-horizontal: 20px;

// Hand-drawn-esque border radius (used in e.g. madlibs)
$hand-drawn-border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
$hand-drawn-border-radius-straighter: 5px 15px 225px 15px/15px 5px 15px 255px;

// ASCII Code for Characters
$ascii-ellipsis: "\2026";

// Add To Shortlist Button
$add-to-shortlist-button-height-mobile: 30px;
$add-to-shortlist-button-height: 40px;
$add-to-shortlist-icon-size: 23px;
$add-to-shortlist-button-width-drawer: 231px;

$standard-checkout-border: #B9B3AC;

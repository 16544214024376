@import "_variables";

@mixin mobile-breakpoint {
  @media only screen and (max-width: #{$max-device-width-mobile}) {
    @content;
  }
}

@mixin tablet-breakpoint {
  @media only screen and (min-width: #{$min-device-width-tablet}) and (max-width: #{$max-device-width-tablet}) {
    @content;
  }
}

@mixin tablet-and-desktop-breakpoint {
  @media only screen and (min-width: #{$min-device-width-tablet}) {
    @content;
  }
}

// Required for non-mobile devices that aren't very tall, i.e. smaller sized monitors
@mixin short-tablet-and-desktop-breakpoint {
  @media only screen and (min-width: #{$min-device-width-tablet}) and (max-height: #{$max-device-height-short}) {
    @content;
  }
}

@mixin mobile-and-tablet-breakpoint {
  @media only screen and (max-width: #{$max-device-width-tablet}) {
    @content;
  }
}

@mixin small-desktop-breakpoint {
  @media only screen and (min-width: #{$min-device-width-desktop}) and (max-width: #{$max-device-width-small-desktop}) {
    @content;
  }
}

@mixin desktop-breakpoint {
  @media only screen and (min-width: #{$min-device-width-desktop}) {
    @content;
  }
}

@mixin laptop-breakpoint {
  @media only screen and (min-width: #{$min-device-width-laptop}) {
    @content;
  }
}

@mixin larger-than-laptop-breakpoint {
  @media only screen and (min-width: #{$min-device-width-laptop + 1}) {
    @content;
  }
}

@mixin smaller-than-laptop-breakpoint {
  @media only screen and (max-width: #{$min-device-width-laptop}) {
    @content;
  }
}

@mixin widescreen-breakpoint {
  @media only screen and (min-width: #{$min-device-width-widescreen}) {
    @content;
  }
}

@mixin smaller-than-widescreen-breakpoint {
  @media only screen and (max-width: #{$min-device-width-widescreen}) {
    @content;
  }
}

@mixin desktop-not-widescreen {
  @media only screen and (min-width: #{$min-device-width-desktop}) and (max-width: #{$widescreen-page-width}) {
    @content;
  }
}

@mixin short-device-breakpoint {
  @media only screen and (max-height: #{$max-device-height-mobile-short}) {
    @content;
  }
}

@mixin short-mobile-breakpoint {
  @media only screen and (max-width: #{$min-device-width-mobile}) {
    @content;
  }
}

@mixin desktop-screen-below ($max-screen-size: #{$max-device-width-small-desktop}) {
  @media only screen and (min-width: #{$min-device-width-desktop}) and (max-width: $max-screen-size) {
    @content;
  }
}

@mixin small-tablet-breakpoint {
  @media only screen and (min-width: #{$min-device-width-small-tablet}) {
    @content;
  }
}
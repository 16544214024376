@import "../../../../stylesheets/sass/partials/variables",
        "../../../../stylesheets/sass/partials/mixins";

.shimmerWrapper {
  margin-left: 35px;
  height: 48px;
  width: 182px;
  padding: 5px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  @include mobile-breakpoint {
    margin-left: 0;
  }

  .block1 {
    margin: auto;
    width: 100%;
    height: 15px;
    margin-bottom: 3px;
    background-color: $color-gray-offwhite;
  }

  .block2 {
    margin: auto;
    width: 80%;
    height: 15px;
    margin-bottom: 3px;
    background-color: $color-gray-offwhite;
  }

  .animation {
    @include shimmer;
  }
}

.emptySpace {
  height: 48px;
  width: 182px;
  margin-left: 35px;
  @include mobile-breakpoint {
    margin-left: 0;
  }
}

.next-banner {
  width: 100vw;
  display: block;
}

.next-banner button {
  background: #D6E5E9;
  color: #0A1C4C;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2rem;
  position: relative;
  transition: color 0.5s, background 0.5s;
  width: 100%;
}

.next-banner button:hover {
  background: #F8D748;
  color: #0A1C4C;
}

.next-banner button::before, .next-banner button::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
  height: 1px;
  background: #fff;
}

.next-banner button::after {
  bottom: 1px;
  top: auto;
}

@media screen and (min-width: 800px) {
  .next-banner {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 200px;
    overflow: hidden;
    height: 200px;
    z-index: 9999;
  }

  .next-banner button {
    width: 200px;
    position: absolute;
    top: 40px;
    right: -40px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
  }
}

@import "partials/_mixins";

.upsell-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  min-height: 100vh;
  position: relative;
}

.upsell-nav {
  margin: 32px 24px 24px 24px;
  display: flex;

  @include mobile-breakpoint {
    margin: 16px 24px 24px 24px;
  }

  &-item {
    text-align: center;
    position: relative;

    &-title {
      position: absolute;
      top: 50%;
      left: 50%;
      width: min-content;
      color: $color-white;
      font-weight: 700;
      line-height: 130%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    &-img {
      max-width: 106px;
      padding: 0 2px;
      border-radius: 4px;
    }
  }
}

.upsell-grid-container {
  flex-grow: 1;
  width: -webkit-fill-available;
}

.head {
  color: $color-content-primary;
  font-weight: 300;
  margin: 24px 0 16px 0;
  font-family: "Martina Plantijn";

  @include mobile-breakpoint {
    color: $color-black;
    font-weight: 400;
    margin: 0 0 8px 0;
    font-family: "Plain";
  }
}

.sub-head{
  color: $color-content-secondary;
  font-weight: 400;
  font-family: "Plain";
}

.upsell-grid {
  box-sizing: border-box;
  width: 100%;

  &-items {
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &-item {
    width: 100%;
    aspect-ratio: 2/3;
  }

  &-title {
    padding-left: 15px;
  }
}

.label {
  &__wrapper {
    margin: 0 0 10px 10px;
  }

  &__title {
    margin-right: 1rem;
    padding-bottom: 0.2rem;

    flex-grow: 1;
    line-height: 1.4;
    overflow: hidden;
    font-weight: 600;
  }

  &__subtitle {
    margin-right: 1rem;
    flex-grow: 1;
    line-height: 1;
    overflow: hidden;
    padding-bottom: 0.8rem;
  }

  &__price_wrapper_space-between {
    justify-content: space-between;
    margin-right: 10px;
  }
  
  &__price {
    margin-right: 4px;      
  }

  &__price_crossed {
    color: #D5D1CD;
    text-decoration: line-through;
    margin-right: 4px;
  }

  &__new_price {
    color: #607F42;
    font-weight: 700;
  }
}

.sticky-ctas {
  position: sticky;
  width: 100%;
  bottom: 0;
  background-color: white;
  padding: 24px 16px;
  box-sizing: border-box;
  box-shadow: 4px -4px 8px 6px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.flex {
  display: flex;
}

.overlay {
  z-index: 1;
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  background: rgba(28, 28, 28, .4);
  height: 98%;
  width: 100%;
  max-width: 106px;
  max-height: 160px;
  margin: 0 2px;
  border-radius: 4px;
}



@import "../../../../../stylesheets/sass/partials/variables",
        "../../../../../stylesheets/sass/partials/mixins";

.container {
  position: relative;
  overflow: hidden;
}

.shimmer-animation {
    @include shimmer;
}

.title-shimmer-block {
    width: 30%;
    height: 18px;
    background-color: $color-gray-offwhite;
    margin-bottom: 8px;
    margin-left: 16px;
}

.img-shimmer-block {
    width: 98%;
    aspect-ratio: 2/3;
    background-color: $color-gray-offwhite;
    margin-bottom: 8px;

    &__title {
        width: 70%;
        height: 18px;
        background-color: $color-gray-offwhite;
        margin-bottom: 10px;
        margin-left: 10px;
    }
    
}
.contentful-banner {
  width: 100vw;
  display: block;

  button {
    background: #091C4C;
    color: #FFF;
    display: inline-block;
    text-decoration: none;
    font-family: WulkanDisplay, serif;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    line-height: 2rem;
    position: relative;
    transition: color 0.5s, background 0.5s;
    width: 100%;
  }

  button:hover {
    background: #A0D1CB;
    color: #0A1C4C;
  }

  button:before,
  button:after {
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    height: 1px;
    background: #FFF;
  }

  button::after {
    bottom: 1px;
    top: auto;
  }
  
  @media screen and (min-width: 800px) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 200px;
    overflow: hidden;
    height: 200px;
    z-index: 9999;

    button {
      width: 200px;
      position: absolute;
      top: 40px;
      left: -40px;
      transform: rotate(315deg);
      -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
      -moz-transform: rotate(315deg);
      -o-transform: rotate(315deg);
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
    }
  }
}

@import "../../../../../stylesheets/sass/partials/nce_common";

.banner {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 10px 16px 16px;
  box-sizing: border-box;

  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: $min-device-width-laptop) {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
}

.header {
  font-weight: bold;
  margin-bottom: 5px;

  @media (min-width: $min-device-width-laptop) {
    font-size: 18px;
  }
}

.p {
  font-size: 14px !important;
  margin-bottom: 20px;

  @media (min-width: $min-device-width-laptop) {
    margin-bottom: 0;
  }
}

.close-button {
  cursor: pointer;
  margin-left: auto;

  @media (min-width: $min-device-width-laptop) {
    display: none;
  }
}

.cta {
  width: 100%;
  cursor: pointer;

  @media (min-width: $min-device-width-laptop) {
    width: 180px;
  }
}

.buttons-holder {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: $min-device-width-laptop) {
    flex-direction: row;
    margin-left: auto;
  }
}
@use "sass:math";

@import
  "_variables",
  "_err_mixins",
  "_mobile2x_mixins",
  "_s32_mixins",
  "_responsive_mixins",
  "_sprites",
  "_css_icons";

// S32 kept in here until it's completely removed.
// Be sure to add mixin in this file and to the  _september_issues.scss file

@mixin box-shadow-modal($color: rgba(0,0,0,0.2), $hoff: 0, $voff: 4px, $blur: 8px, $spread: null, $inset: null){
  @include single-box-shadow($hoff, $voff, $blur, $spread, $color, $inset)
}

@mixin box-shadow-header($color: rgba(0,0,0,0.2), $hoff: 0, $voff: 2px, $blur: 4px, $spread: null, $inset: null){
  @include single-box-shadow($hoff, $voff, $blur, $spread, $color, $inset)
}

@mixin appearance($appearance) {
  $appearance: unquote($appearance);
}

@mixin single-box-shadow($values...) {
  box-shadow: $values;

}

@mixin apply-origin($origin, $only3d) {
  $capability: if($only3d or length($origin) > 2, transforms3d, transforms2d);
  @include prefixed-properties($capability, $transform-support-threshold, (
    transform-origin: $origin
  ));
}

@mixin transform($values...) {
  transform: $values;
}

@mixin transform-origin(
  $origin-x: $default-origin-x,
  $origin-y: $default-origin-y,
  $origin-z: false,
  $only3d:   if($origin-z, true, false)
) {
  $origin: unquote("");
  @if $origin-x or $origin-y or $origin-z {
    @if $origin-x { $origin: $origin-x; } @else { $origin: 50%; }
    @if $origin-y { $origin: $origin $origin-y; } @else { @if $origin-z { $origin: $origin 50%; }}
    @if $origin-z { $origin: $origin $origin-z; }
    @include apply-origin($origin, $only3d);
  }
}

@mixin rotate($value) {
  transform: rotate($value);
}

@mixin rotateY($value) {
  transform: rotateY($value);
}

$current-prefix: null;
$graceful-usage-threshold: 0.1 !default;
$animation-support-threshold: $graceful-usage-threshold !default;
$default-capability-options: (
  (full-support: true),
  (partial-support: true)
);
$transform-support-threshold: $graceful-usage-threshold !default;

$default-transition-property: all !default;

$default-transition-duration: 1s !default;

$default-transition-function: null !default;

$default-transition-delay: null !default;

@mixin single-transition(
  $property: $default-transition-property,
  $duration: $default-transition-duration,
  $function: $default-transition-function,
  $delay: $default-transition-delay
) {
  @include transition(compact($property $duration $function $delay));
}

@mixin transition($values...) {
  transition: $values;
}

@mixin transform-style($style: preserve-3d) {
  transform-style: $style;
}

@mixin backface-visibility($visibility: hidden) {
  backface-visibility: $visibility
}

@mixin animation($values) {
  animation: $values
}

@mixin animation-properties($properties) {
  @include prefixed-properties(css-animation, $animation-support-threshold, $properties);
}

@mixin animation-delay($delay...) {
  $delay: set-arglist-default($delay, null);
  @include animation-properties((animation-delay: $delay));
}

@mixin prefixed-properties($capability, $threshold, $properties, $capability-options: $default-capability-options) {
  @each $prop, $value in $properties {
    @include prefix-prop($prop, $value);
  }
}

@mixin prefix-prop($property, $value, $prefix: $current-prefix) {
  #{prefix-identifier($property, $prefix)}: $value;
}

@function prefix-identifier($ident, $prefix: $current-prefix) {
  @return unquote("#{$prefix}#{if($prefix, '-', null)}#{$ident}");
}

// Create a named animation sequence that can be applied to elements later.
//
//     $name    - The name of your animation.
//     @content - The keyframes of the animation.
@mixin keyframes($name) {
  @keyframes #{$name} { @content; }
  // @include with-each-prefix(css-animation, $animation-support-threshold) {
  //   // It would be nice if we could dynamically construct directive names.
  //   @if $current-prefix == -moz    { @-moz-keyframes    #{$name} { @content; } }
  //   @if $current-prefix == -webkit { @-webkit-keyframes #{$name} { @content; } }
  //   @if $current-prefix == -o      { @-o-keyframes      #{$name} { @content; } }
  //   @if $current-prefix == -ms     { @-ms-keyframes     #{$name} { @content; } }
  //   @if $current-prefix == null    { @keyframes         #{$name} { @content; } }
  // }
}

@mixin no-box-shadow {
  @include single-box-shadow(0, 0, 0, 0, transparent);
}

@mixin columns($width-and-count) {
  columns: $width-and-count
}

// Specify the number of columns
@mixin column-count($count) {
  column-count: $count
}

// Specify the gap between columns e.g. `20px`
@mixin column-gap($width) {
  column-gap: $width
}

@mixin column-break($type: before, $value: auto){
  -webkit-column-break-#{$type}: $value;
  -moz-page-break-#{$type}: $value;
}

// Mixin for setting break-inside
//
// * legal values are auto, avoid, avoid-page, avoid-column
//
//  Example:
//    h2.inside {@include break-inside();}
//
//  Which generates:
//
//  h2.inside {
//    -webkit-column-break-inside: auto;
//    break-inside: auto;}
@mixin break-inside($value: auto){
  @include column-break(inside, $value);
}

// mega clearfix complete with all the browser-specific hacks
@mixin clearfix {
  zoom: 1;

  &:after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    font-size: 0;
    visibility: hidden;
  }
}

@mixin opacity($n) {
  opacity: $n;
  filter: alpha(opacity=$n*100);
}

@mixin retina2x {
  @include retina(0.5);
}

@mixin retina($zoom) {
  zoom: $zoom;
  // Firefox doesn't support zoom
  -moz-transform: scale($zoom);
  -moz-transform-origin: top left;
}

@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}

@mixin loading($background-color: $color-background) {
  background: $background-color no-repeat scroll center center;
  background-size: 98px 134px;
  background-image: $loading;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: $loading-retina;
  }
}

@mixin loading-small($background-color: $color-background) {
  background: $background-color no-repeat scroll center center;
  background-size: 23px 31px;
  background-image: $loading;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: $loading-small-retina;
  }
}

@mixin font($size, $weight: normal) {
  font-size: $size;
  font-weight: $weight;
}

@mixin anti-alias(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-standard-content-light($font-size: $font-size-default) {
  @include body-font($font-size);
}
@mixin font-standard-content($font-size: $font-size-default) {
  @include body-font($font-size);
}
@mixin font-standard-content-strong($font-size: $font-size-default) {
  @include body-font-heavy($font-size);
}
@mixin font-standard-content-heavy($font-size: $font-size-default) {
  @include body-font-heavy($font-size);
}
@mixin font-standard-content-thin($font-size: $font-size-default) {
  @include body-font($font-size);
}

// I'm not sure why we would place mixins second in these blocks...
// sass-lint:disable mixins-before-declarations
@mixin font-standard-subhead-light($font-size: $font-size-default) {
  @include font-standard-content-light($font-size);
  text-transform: uppercase;

  @include body-font($font-size);
}
@mixin font-standard-subhead($font-size: $font-size-default) {
  @include font-standard-content($font-size);
  text-transform: uppercase;

  @include body-font($font-size);
}
@mixin font-standard-subhead-strong($font-size: $font-size-default) {
  @include font-standard-content-strong($font-size);
  text-transform: uppercase;

  @include body-font-heavy($font-size);
}
@mixin font-standard-subhead-heavy($font-size: $font-size-default) {
  @include font-standard-content-heavy($font-size);
  text-transform: uppercase;

  @include body-font-heavy($font-size);
}

@mixin font-standard-headline-light($font-size: $font-size-default) {
  @include font-standard-content-light($font-size);
  text-transform: uppercase;

  @include body-font($font-size);
}
@mixin font-standard-headline($font-size: $font-size-default) {
  @include font-standard-content($font-size);
  text-transform: uppercase;

  @include body-font($font-size);
}
@mixin font-standard-headline-strong($font-size: $font-size-default) {
  @include font-standard-content-strong($font-size);
  text-transform: uppercase;

  @include body-font-heavy($font-size);
}
@mixin font-standard-headline-heavy($font-size: $font-size-default) {
  @include font-standard-content-heavy($font-size);
  text-transform: uppercase;

  @include body-font-heavy($font-size);
}

@mixin font-sexy-subhead($font-size: $font-size-default) {
  @include title-font-italic($font-size);
}
@mixin font-sexy-subhead-strong($font-size: $font-size-default) {
  @include title-font-italic($font-size);
}

@mixin font-sexy-headline($font-size: $font-size-heading) {
  @include font-sexy-subhead($font-size);
}
@mixin font-sexy-headline-strong($font-size: $font-size-heading) {
  @include font-sexy-subhead-strong($font-size);
}

@mixin font-statement-subhead-light($font-size: $font-size-heading) {
  @include title-font-italic($font-size);
}
@mixin font-statement-subhead($font-size: $font-size-heading) {
  @include title-font-italic($font-size);
}
@mixin font-statement-subhead-strong($font-size: $font-size-heading) {
  @include title-font-italic($font-size);
}

@mixin font-statement-headline-light($font-size: $font-size-heading) {
  @include font-statement-subhead-light($font-size);
}
@mixin font-statement-headline($font-size: $font-size-heading) {
  @include font-statement-subhead($font-size);
}
@mixin font-statement-headline-strong($font-size: $font-size-heading) {
  @include font-statement-subhead-strong($font-size);
}

@mixin font-classy-headline($font-size: $font-size-heading){
  @include title-font($font-size);
}

@mixin font-classy($font-size: $font-size-heading){
  @include title-font($font-size);
}

@mixin font-classy-italic($font-size: $font-size-heading){
  @include title-font-italic($font-size);
}

@mixin font-classy-light-italic($font-size: $font-size-heading){
  @include title-font-italic($font-size);
}

@mixin font-classy-light($font-size: $font-size-heading){
  @include title-font-italic($font-size);
}

@mixin font-title-light($font-size: 40px) {
  @include title-font($font-size);
}

@mixin font-title-italic($font-size: 40px) {
  @include title-font-italic($font-size);
}

@mixin font-headline-em() {
  text-transform: none;
}
@mixin font-subhead-em() {
  @include font-headline-em;
}

// Rebrand mixins

@mixin body-font($size: inherit) {
  @include rebrand-font($size, 300);
}

@mixin body-font-heavy($size: inherit) {
  @include rebrand-font($size, 500);
}

@mixin body-font-italic($size: inherit) {
  @include rebrand-font($size, 500);
}

@mixin body-font-light-italic($size: inherit) {
  @include rebrand-font($size, 500);
}

@mixin title-font($size: inherit) {
  text-transform: none;
  @include rebrand-font($size, 300);
}

@mixin title-font-light-italic($size: 26px) {
  text-transform: none;
  @include rebrand-font($size, 300);
}

// Oy, this is actually body-font. @todo remove references to this mixin in favor of cascade
@mixin title-font-italic($size: inherit) {
  text-transform: none;
  @include rebrand-font($size, 500);
}

@mixin rebrand-font($size: inherit, $weight: normal) {
  letter-spacing: 0;
  @include font($size, $weight);
}
// sass-lint:enable mixins-before-declarations
// Rebrand grid

@mixin grid-wrapper {
  padding-left: $grid-horizontal-padding - $grid-gutter;
  padding-right: $grid-horizontal-padding - $grid-gutter;
  // the width of the wrapper should include the gutters between each column PLUS half a gutter on each side. math left unsimplified to reveal intent.
  width: $grid-column-width * $grid-max-column-width + ($grid-gutter * ($grid-max-column-width - 1)) + ($grid-gutter * 0.5 * 2);
}

@function grid-width-for-columns($column-count: 1) {
  @return $grid-column-width * $column-count + $grid-gutter * ($column-count - 1);
}

@mixin grid-width($column-count: 1, $flexible: false) {
  @if $flexible {
    max-width: grid-width-for-columns($column-count);
  } @else {
    width: grid-width-for-columns($column-count);
  }
}

@mixin grid-cell($columns) {
  float: left;
  margin: $fluid-grid-gutter * 0.5;
  width: $columns * $fluid-grid-column + (($columns - 1) * $fluid-grid-gutter);
}

@mixin grid-cell-absorb-gutter-right($columns) {
  @include grid-cell($columns);
  width: $columns * $fluid-grid-column + (($columns - 1) * $fluid-grid-gutter) + $fluid-grid-gutter; // math left unsimplified to reveal intent
  margin-right: math.div($fluid-grid-gutter, -2);
}

// Layout

@mixin base-standard-content-section {
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @include font-standard-content; // sass-lint:disable-line mixins-before-declarations
}
@mixin original-standard-content-section {
  @include base-standard-content-section;
  width: 990px;
}
@mixin standard-content-section {
  @include base-standard-content-section;
  width: $page-width;
}
@mixin widescreen-content-section {
  @include base-standard-content-section;
  width: 100%;
  max-width: $widescreen-page-width;
}

@mixin section-title-bar($padding: 10px 30px) { // I would like to see references to this disappear in favor of adding .section-block-title to the element which should be styled this way
  @include clearfix;
  position: relative;
  padding: $padding;
  color: $color-primary-copy;
}

@mixin section-header { // same as above, would be better to consistenly apply a class. for REBRAND rollout, need overrides
  @include no-box-shadow;
  background-color: $color-background-two;
  color: $color-primary-copy;
  padding: 12px 24px;
}

@mixin fancy-border-top($additional-top-padding: 0) {
  padding-top: 1px + $additional-top-padding;
  background: transparent url("//cdn.rtrcdn.com/sites/default/files/images/dotted-bg-for-borders.png") repeat-x top left;
}

@mixin set-identical-absolute-positioning($value) {
  top: $value;
  right: $value;
  bottom: $value;
  left: $value;
}

@mixin error-message {
  color: $color-error;
  background-color: $color-background-one;
}

$pretty-checkbox-options: 12 !default; // the number of options
$pretty-checkbox-option-rows: 3 !default;
$pretty-checkbox-width: 25px !default;
@mixin pretty-checkbox-set($dropdown: true, $option-count: $pretty-checkbox-options, $option-rows: $pretty-checkbox-option-rows, $label-width: $pretty-checkbox-width) {
  position: relative;
  overflow: visible;

  $options-per-row: ceil(math.div($option-count, $option-rows));
  $checkbox-set-width: $options-per-row * ($label-width + 2 + 2); // +2, margin, +2 border
  $checkbox-set-padding: 10px;

  @if($dropdown) {
    width: $checkbox-set-width + 2 * $checkbox-set-padding;
  }

  .checkbox-set {
    @if($dropdown) {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      padding: $checkbox-set-padding;
      width: $checkbox-set-width;
      background: white;
      z-index: 1; //SONUVA!

      @include box-shadow-modal;  // sass-lint:disable-line mixins-before-declarations
    } @else {
      padding: $checkbox-set-padding 0;
    }

    @include clearfix;
  }

  .pretty-checkbox {
    float: left;
    margin: 0;
    border: 0;
    padding: 0;
    overflow: hidden;

    width: if($dropdown, "auto", math.div(100%, $options-per-row));

    input[type="checkbox"] {
      display: none;
    }

    label {
      display: block;
      margin: 0 2px 2px 0;
      border: 1px solid $border-color;
      padding: 0;
      width: if($dropdown, $label-width, "auto");
      background-color: $color-background-one;
      line-height: 2.0;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $color-background-one;
      }
    }

    &.checked {

      label {
        background-color: white;
      }
    }
  }

  @if($dropdown) {

    .no-touch &:hover {

      .checkbox-set {
        display: block;
      }
    }
  }
}

@mixin pretty-checkbox-set-label {
  display: block;
  padding: 11px 22px 12px 10px; // matches inputs site-wide
  padding-right: 22px;
  border: 1px solid $color-lines;
  // SVG is well-supported. TODO: Check this renders ok without the PNG
  // sass-lint:disable no-duplicate-properties
  background: $color-background url("/images/sprites/reb/drop_arrow_small.png") no-repeat 93% center;
  background: $color-background url("/images/sprites/reb/svg/drop_arrow_small.svg") no-repeat 93% center;
  // sass-lint:enable no-duplicate-properties
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;

  // @include text-shadow(0 1px 1px rgba(white,0.75));

  &.empty {
    color: $color-primary-copy;
  }

  &:hover {
    background-color: $border-color;
  }
}

$background-color: $color-background !default;
@mixin tooltip ($width: 280px, $tooltip-border-color: $border-color) {
  position: absolute;
  top: 40px;
  width: $width;
  padding: 30px;
  border: 1px solid $tooltip-border-color;
  background-color: $background-color;
  z-index: 3;

  .title {
    @include font-standard-subhead-strong($font-size-default);
    text-transform: uppercase;
    text-align: center;
  }

  .actions {
    text-align: center;
  }

  $nib-height: 10px;
  $nib-width: 20px;

  &:before,
  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-width: $nib-height ($nib-width * 0.5);
    left: 50%;
    top: 0;
    margin-top: -2 * $nib-height - 1;
    margin-left: $nib-width * -1;
    border-bottom-color: $tooltip-border-color;
    position: absolute;
  }

  &:after {
    top: 1px;
    border-bottom-color: $background-color;
  }

  .title {
    margin-top: -30px;
    line-height: 50px;
  }
}

@mixin datepicker-input($width: 250px) {
  $iconWidth: 25px;
  width: $width;
  // SVG is well-supported. TODO: Check this renders ok without the PNG
  // sass-lint:disable no-duplicate-properties
  background: $color-background url("/images/sprites/reb/calendar.png") no-repeat 95% 50%;
  background: $color-background url("/images/sprites/reb/svg/calendar.svg") no-repeat 95% 50%;
  // sass-lint:enable no-duplicate-properties
  padding-right: $iconWidth + 1px;
  cursor: pointer;
}

@mixin hand-drawn-input($border-radius: $hand-drawn-border-radius) {
  @include proxima-body-copy;
  background: $color-background url("/images/sprites/reb/svg/dark_drop_arrow_sm.svg") no-repeat right 12px center;
  border: 2px solid $color-primary-copy;
  border-radius: $border-radius;
  cursor: pointer;
  letter-spacing: 1.8px;
  line-height: 30px;
  padding: 5px 30px 5px 10px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;
}

@mixin rebrand-button($color) {
  background-color: $color;
  display: block;
  text-transform: uppercase;
  height: 44px;
  padding: 10px 30px 6px;
  line-height: 1em;
  letter-spacing: 2px !important; // sass-lint:disable-line no-important
  border: 0 none;
}

@mixin rebrand-button-active {
  @include font-standard-content($font-size-default);
  @include rebrand-button($color-primary-copy);
  color: $color-background;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $color-primary-copy;
  }
}

@mixin rebrand-button-disabled {
  @include font-standard-content($font-size-default);
  @include rebrand-button($color-background-one);
  color: $color-primary-copy;
}

@mixin mega-menu($color) {
  // sass-lint:disable-block no-important
  font: $color !important;
  padding-bottom: 2px;
  border-bottom: 1px solid $color !important;

  &:hover {
    $new-color: lighten($color, 10);
    color: $new-color;
    border-bottom: 1px solid $new-color !important;
  }
}

@mixin item-dimensions($height, $imageWidth, $imageHeight, $margin-bottom: 10px) {

    .user-item {
        height: $height;
    }
}

@mixin iphone5-breakpoint {
  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 374px)
  and (orientation : portrait) {
    @content;
  }
}

@mixin modal-container($modal-class) {

  .mfp-wrap.#{ $modal-class } {

    .mfp-content,
    .mfp-inline-holder .mfp-content {
      @content;
    }
  }
}

@mixin flip-animation($container-class, $content-class, $direction, $transition-speed, $perspective, $width) {

  .#{ $container-class } {
    @include perspective($perspective);
  }

  .#{ $content-class },
  .card-front,
  .card-back {
    width: $width;
  }

  .card-front,
  .card-back {
    position: absolute;
  }

  .#{ $content-class } {
    @include transition(transform $transition-speed);
    @include transform-style(preserve-3d);
    position: relative;
  }

  .card-back {
    @include rotateY($direction);
    visibility: hidden;
  }

  .card-flip {
    @include rotateY($direction);
    overflow: hidden;

    .card-front {
      visibility: hidden;
    }

    .card-back {
      visibility: visible;
    }
  }
}

@mixin flip-animation-grid($container, $content, $direction, $transition-speed, $perspective, $width, $height, $transition-timing-function: ease) {

  #{ $container } {
    @include perspective($perspective);
  }

  #{ $content } {
    @include transition(transform $transition-speed $transition-timing-function);
    @include transform-style(preserve-3d);
    position: relative;

    &.card-flip {
      @include rotateY($direction);
      overflow: hidden;

      > :first-child {
        position: absolute;
        visibility: hidden;
        width: $width;
        height: $height;
      }

      > :last-child {
        position: absolute;
        @include rotateY($direction);  // sass-lint:disable-line mixins-before-declarations
        visibility: visible;
        width: $width;
        height: $height;
      }
    }
  }
}

@mixin pulse-animation($selector) {

  #{ $selector } {
    @include animation(pulse-animation 0.4s linear both);
  }
}

@mixin card-bounce-flip {

  .card-bounce-flip {
    @include animation(card-bounce-flip 0.5s forwards);

    > :first-child {
      @include animation(card-bounce-show 0.5s forwards);
    }

    > :last-child {
      @include animation(card-bounce-hide 0.5s forwards);
    }
  }
}

@mixin slide($direction) {
  @include transition($direction 0.2s cubic-bezier(0.65, 0.05, 0.36, 1));
}

@mixin border-accent {
  border: 1px solid $border-accent-color;
}

@mixin border($color: $color-lines) {
  border: 1px solid $color;
}

@mixin border-bottom($color: $color-lines) {
  border-bottom: 1px solid $color;
}

// I don't know why we put the mixin last...
// sass-lint:disable mixins-before-declarations
@mixin h1 {
  font-size: $font-size-heading-one;

  @include heading;
  @include mobile-breakpoint {
    font-size: $font-size-heading-one-mobile;
  }
}

@mixin h2 {
  font-size: $font-size-heading-two;

  @include heading;
  @include mobile-breakpoint {
    font-size: $font-size-heading-two-mobile;
  }
}

@mixin h3 {
  font-size: $font-size-heading-three;

  @include heading;
  @include mobile-breakpoint {
    font-size: $font-size-heading-three-mobile;
  }
}

@mixin h4 {
  font-size: $font-size-heading-four;

  @include heading;
  @include mobile-breakpoint {
    font-size: $font-size-heading-four-mobile;
  }
}

@mixin h5 {
  font-size: $font-size-title;

  @include heading;
  @include mobile-breakpoint {
    font-size: $font-size-heading-five-mobile;
  }
}

@mixin h6 {
  font-size: $font-size-title;

  @include heading;
  @include mobile-breakpoint {
    font-size: $font-size-heading-six-mobile;
  }
}
// sass-lint:enable mixins-before-declarations

// CMS Heading mixins should use more semantic
// classes for clarity
@mixin header-cms-36 {
  @include heading;
  font-size: $font-size-cms-36;
}

@mixin header-cms-28 {
  @include heading;
  font-size: $font-size-cms-28;
}

@mixin header-cms-24 {
  @include heading;
  font-size: $font-size-cms-24;
}

@mixin header-cms-20 {
  @include heading;
  font-size: $font-size-cms-20;
}

@mixin home-hero-hed {
  @include heading;
  font-size: $font-size-home-hero;

  @include mobile-and-tablet-breakpoint {
    font-size: $font-size-home-hero-mobile;
  }
}

@mixin hero-dek {
  font-size: $font-size-body-one;
  line-height: $line-height-dek-two;

  @include mobile-and-tablet-breakpoint {
    font-size: $font-size-body-two;
    line-height: $line-height-smaller;
  }
}

@mixin italic {
  font-style: italic;
}

@mixin inline-link {
  @include heading;
  font-size: inherit;
  text-decoration: underline;
}

@mixin dek-one {
  @include heading;
  font-size: $font-size-dek-one;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}

@mixin dek-two {
  font-size: $font-size-dek-two;
  line-height: 21px;
}

@mixin body-copy {
  @include heading;
  font-size: $font-size-default;
  line-height: 22px;
  letter-spacing: 0;
  text-transform: none;
}

@mixin input-copy {
  font-size: $font-size-input;
  line-height: 22px;
  letter-spacing: 0;
  text-transform: none;
}

// ONLY USE THIS MIXIN IF YOUR TEXT SPANS FROM EDGE TO EDGE ~Ying //
@mixin editorial-copy {
  @include heading;
  font-size: $font-size-editorial;
  line-height: 1.33; // line-height should be unitless.  I'm looking at you .body-copy! //
  letter-spacing: 0;
  text-transform: none;
}

@mixin small-copy {
  font-size: $font-size-small;
  line-height: 18px;
  text-transform: none;
  letter-spacing: 0;
}

@mixin title {
  @include semibold;
  letter-spacing: $letter-spacing-title;
  font-size: $font-size-title;
  text-transform: uppercase;
}

@mixin tagline {
  @include semibold;
  letter-spacing: $letter-spacing-tagline;
  font-size: $font-size-small;
  text-transform: uppercase;
}

@mixin promotional {
  font-size: $font-size-promotional;
  line-height: $font-size-promotional;
}

@mixin update {
  @include small-copy;
  color: $color-primary-copy;
  background-color: $color-background-two;
  padding: 12px 0;
  text-transform: center;
}

@mixin proxima-small-copy {
  font-size: $font-size-small;
  line-height: $font-size-default;
}

@mixin proxima-lowercase-small-copy {
  font-size: $font-size-small;
  line-height: $font-size-default;
  text-transform: none;
  letter-spacing: 0;
}

@mixin proxima-body-copy {
  font-size: $font-size-title;
  line-height: $line-height-small;
}


@mixin proxima-semibold-body-copy {
  @include semibold;
  font-size: $font-size-title;
  line-height: $line-height-small;
}

@mixin proxima-header {
  font-size: $font-size-title;
  line-height: $line-height-small;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-proxima-title;
}

@mixin proxima-semibold {
  @include semibold;
  font-size: $font-size-small;
  line-height: $font-size-default;
  letter-spacing: $letter-spacing-proxima-title;
}

@mixin proxima-input-copy {
  @include normal-weight;
  font-size: $font-size-input;
  line-height: $font-size-default;
}

@mixin proxima-inline-cta {
  font-size: $font-size-default;
  line-height: $line-height-body;
}

@mixin proxima-link {
  
  font-size: $font-size-subtitle;
  color: $color-primary-copy;
  text-transform: uppercase;
  text-decoration: underline;
  line-height: $font-size-default;
  cursor: pointer;
}

@mixin about-us-text {
  @include proxima-body-copy;
  font-size: $font-size-editorial;
  line-height: 1.44;
  @include mobile-breakpoint {
    font-size: $font-size-title;
    line-height: 1.5;
  }
}

@mixin error-block {
  @include small-copy;
  color: $color-background;
  background-color: $color-error;
  padding: 12px 0;
  text-align: center;
}

@mixin section-head {
  border-bottom: 1px solid $color-lines;
}

@mixin label {
  text-transform: uppercase;
  font-size: $font-size-label;
  letter-spacing: $letter-spacing-title;
  line-height: 18px;
}

@mixin breadcrumb {
  
  font-size: $font-size-label;
  color: $color-primary-copy;
}

@mixin anchor-underline {
  //Ensures a consistent line height for all of the links and
  //thus consistent padding below for underline.
  line-height: 100%;
  padding-bottom: 2px;
  border-bottom: 1px solid currentColor;
}

@mixin standalone {
  @include semibold;
  font-size: $font-size-label;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-title;
  color: inherit;

  @include anchor-underline;  // sass-lint:disable-line mixins-before-declarations
}

@mixin standalone-title {
  @include standalone;
  font-size: $font-size-title;
}

@mixin btn {
  @include semibold;
  letter-spacing: $letter-spacing-title;
  font-size: $font-size-title;
  cursor: pointer;
}

@mixin alert {
  color: $color-error;
}

@mixin accent {
  color: $color-accent;
}

@mixin button-hover {
  opacity: 0.8;
}

@mixin pill($diameter, $color) {
    border-radius: $diameter;
    height: $diameter;
    width: $diameter;
    background-color: $color;
    text-align: center;
}

@mixin button-secondary {
  border: 1px solid $color-primary-copy;
  background: transparent;
  color: $color-primary-copy;
  cursor: pointer;
}

@mixin button-disabled {
  background-color: $color-background-one;
  color: $color-secondary-copy;
  border: none;
}

@mixin button-link {
  @include proxima-semibold;
  background-color: inherit;
  border: none;
  color: inherit;
  height: inherit;
  letter-spacing: 1.4px;
  padding: 0;
  text-decoration: underline;
}

@mixin input-placeholder {

  ::-webkit-input-placeholder {
    font-family: inherit;
    color: $color-secondary-copy;
  }

  ::-moz-placeholder {
    font-family: inherit;
    color: $color-secondary-copy;
  } // firefox 19+

  :-ms-input-placeholder {
    font-family: inherit;
    color: $color-secondary-copy;
  } // ie

  input:-moz-placeholder {
    font-family: inherit;
    color: $color-secondary-copy;
  }
}

@mixin mobile-small-copy {
  @include mobile-breakpoint {
    font-size: $font-size-small;
  }
}

@mixin datepicker-hover-duration-4 {

  &,
  & + .datepicker-input-wrapper,
  & + .datepicker-input-wrapper + .datepicker-input-wrapper,
  & + .datepicker-input-wrapper + .datepicker-input-wrapper + .datepicker-input-wrapper {
    @content;
  }
}

@mixin position-sticky {
  // position: sticky is not well supported across browsers
  // sass-lint:disable no-duplicate-properties
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  // sass-lint:enable no-duplicate-properties
}

@mixin square-image($mobile-size, $desktop-size) {
  height: $mobile-size;
  width: $mobile-size;
  @include tablet-and-desktop-breakpoint {
    height: $desktop-size;
    width: $desktop-size;
  }
}

@mixin reserve-tab {
  border-top: 8px solid $color-primary-copy;
  margin-right: 2px;
  @include mobile-breakpoint {
    border-top: 4px solid $color-primary-copy;
  }
}

@mixin unlimited-tab {
  border-top: 8px solid $color-unlimited;
  margin-right: 2px;
  @include mobile-breakpoint {
    border-top: 4px solid $color-unlimited;
  }
}

@mixin rtrupdate-tab {
  border-top: 8px solid $color-update;
  margin-right: 2px;
  @include mobile-breakpoint {
    border-top: 4px solid $color-update;
  }
}

@mixin truncate-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin membership-item-cards {
  align-items: center;
  background-color: $color-background;
  border-radius: 2px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  height: 122px;
  justify-content: center;
  position: relative;
  width: 73px;
}

@mixin ai-search-icon {
  background-image: url("/images/AISearchIcon.svg");
  width: 30px;
  height: 30px;
}

@mixin lightbulb-icon {
  background-image: url("/images/img_lightbulb_blueBG.svg");
  width: 70px;
  height: 70px;
}

@mixin set-aspect-ratio {
  aspect-ratio: var(--desktop-aspect-ratio);

  @include mobile-and-tablet-breakpoint {
    aspect-ratio: var(--mobile-aspect-ratio);
  }
}

@mixin shimmer {
  position: absolute;
  top: -200%;
  left: -200%;
  width: 220%;
  height: 250%;
  background: linear-gradient(-55deg, transparent 20%, white 30%, transparent 40%);
  animation: shimmer-animation 2s infinite linear;
}

@keyframes shimmer-animation {
  100% {
    transform: translate(100%, 100%);
  }
}

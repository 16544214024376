@import "../../../../../stylesheets/sass/partials/_nce_common.scss";

.overlay {
  :global {
    .ReactModal__Content.ReactModal__Content--after-open {
      @include small-tablet-breakpoint {
        margin: unset;
      }
    }
  }
}

.modal {   
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 0;
  top: auto;

  @include desktop-breakpoint {
    left: unset;
    width: 488px;
    right: 0;
    top: 0;
    height: 100vh;
    margin: 0;
  }

  :global {
    .modal-wrapper {
      padding-top: 10px;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
    }

    .modal-close {
      top: 10px;
    }

    #btn-add-to-shortlist {
      display: none;
    }
  }
}

.header {
  display: grid;
  height: 50px;
  grid-template-columns: 60px 1fr 60px;
  align-items: center;
  text-align: center;
  font-size: 20px;

  flex: none;
}

.mini-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 16px;
}

.mini-grid-cell {
  width: 100%;
}

.image-holder {
  display: block;
  width: 100%;
  height: 280px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include desktop-breakpoint {
    height: 365px;
  }
}

.text-holder {
  padding: 10px 8px 10px;
  display: block;
}

.designer-name {
  font-size: 14px;
  color: #000;
}

.style-name {
  font-size: 12px;
  color: #666;
}

.footer {
  height: 80px;
  flex: none;

  display: flex;
  padding: 10px 0 20px;
  box-sizing: border-box;

  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 8px;
  padding: 0 20px;

  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.14);

  button {
    flex: 1;

    border-radius: 5px;
    border: 1px black solid;
    height: 44px;
    cursor: pointer;
  }
}

.cta {
  border: none;
  background-color: #000;
  color: #fff;
}
